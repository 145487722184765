import React from 'react';
import classes from './contactPage.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Auxil from '../../hoc/Auxil/Auxil';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";
  

const contactPage = (props: any) => (
    <Auxil>
        <div className={classes.myContact}>
            <h1 className={classes.contactTitle}>My Contact Info</h1>
            <p className={classes.emailLink}>You can easily reach me at my email <a href='mailto:christian.d.kempis@gmail.com'>christian.d.kempis@gmail.com</a></p>
            <br/>
            <Container className={classes.socialMedia}>
                <Row>
                    <p>Check out my social media links below too!</p>
                </Row>
                <Row>
                    <LinkedinShareButton title={'My LinkedIn'} url={'https://www.linkedin.com/in/christian-kempis-8b85b769'}>
                        <LinkedinIcon size={64} />
                    </LinkedinShareButton>
                    <FacebookShareButton title={'My Facebook'} url={'https://www.facebook.com/CrazyCKF00L'}>
                        <FacebookIcon size={64}/>
                    </FacebookShareButton>
                    <EmailShareButton subject={'Lets Connect!'} url={'mailto:christian.d.kempis@gmail.com'}>
                        <EmailIcon size={64}/>
                    </EmailShareButton>

                </Row>
            </Container>
        </div>
        
    </Auxil>
);

export default contactPage;