import React from 'react';
import classes from './homePage.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import itsMe from '../../assets/me_hawaii2.png';

import PageContext from '../../context/pageContext';

const homePage = (props: any) => (
    <PageContext.Consumer>
        {(context) =>     
        <div>
            <section className={classes.introHeader}>
                <div>
                    <h1 className={classes.myName}>Christian Kempis</h1>
                    <h3 className={classes.myNickName}>Its just Chris</h3>
                    <h4 className={classes.mySideNote}>I make software and stuff</h4>
                </div>
            </section>
            <section className={classes.aboutSection}>
                <Container className={classes.aboutContainer}>
                    <Row>
                        <Col className={["d-flex justify-content-center"].join(" ")}>
                            <img className={[classes.myImage].join(" ")} src={itsMe} alt="Hawaii was great"></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2 className={classes.whoAmI}>Who I am</h2>
                        </Col>
                    </Row>
                    <Row className={classes.myStory}>
                        <Col>
                        <p>Hey there, nice to meet you.  My name is Christian or just Chris for short.  I am a Senior Software Engineer based in San Diego.</p>
                        <br/>
                        <p>I have a bunch of experience in Embedded Software Development in C and C++ and Web Development experience in JavaScript with React and Angular.
                            So if you want a flight vehicle with fully integrated navigation and actuator control that happens to also host your company's online 
                            webstore then I think you just found your guy.</p>
                        <br/>
                        <p>Im currently working in Web Development with focus on implementing data driven analytics with Machine Learning.  
                            If you're interested in working together then feel free contact me.  Im always looking out for exciting opportunities.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>}
    </PageContext.Consumer>
);

export default homePage;