import React, {Component} from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import HomePage from '../components/homePage/homePage';
import ContactPage from '../components/contactPage/contactPage';
import BlogPage from '../components/blogPage/blogPage';
import Auxil from '../hoc/Auxil/Auxil';

import {Global} from '../context/Global';
import PageContext from '../context/pageContext';


class MainPage extends Component {
    state = {
        currentPage: Global.homePage
    };

    handlePageSwitch = (page: String) => {
        const currentPage: String = this.state.currentPage;
        if(currentPage === page) {
            return;
        }
        const updatedPage = page;
        this.setState({currentPage: updatedPage});
    }

    render = () => {
        let displayPage: any = <HomePage/>;
        if(this.state.currentPage === Global.contactPage) {
            displayPage = <ContactPage/>
        } else if(this.state.currentPage === Global.blogPage) {
            displayPage = <BlogPage />
        }
        return (
            <Auxil>
                <section>
                    <Navbar bg="light" variant="light">
                      <Navbar.Brand onClick={() => this.handlePageSwitch(Global.homePage)}>CKempis</Navbar.Brand>
                      <Nav className="mr-auto">
                        <Nav.Link disabled onClick={() => this.handlePageSwitch(Global.blogPage)}>Blog</Nav.Link>
                      </Nav>
                      <Nav>
                        <Nav.Link onClick={() => this.handlePageSwitch(Global.contactPage)}>Contact Me</Nav.Link>
                      </Nav>
                    </Navbar>
                </section>
                <PageContext.Provider value={{currentPage: this.state.currentPage, handlePageClick: this.handlePageSwitch}}>
                    {displayPage}
                </PageContext.Provider>
            </Auxil>
        );
    }
}

export default MainPage;